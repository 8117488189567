@import '../../styles/global.scss';

.plans-container {

    background: $background;
    height: 100%;
    overflow: auto;
    width: 100%;

    .header {

        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .add-button {
            padding-inline: 2rem;
        }
    }

    
}
