@import '../../styles/global.scss';

.videos-container {

    background: $background;
    height: 100%;
    overflow: auto;
    width: 100%;

    .header {

        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .upload-button {
            padding-inline: 2rem;
        }
    }

    .pagination {

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: 2rem;
        padding-inline: 2.5rem;
        padding-block: 1rem;
    }
    
    .ant-pagination-item-active {
    
        background: $primary;
        border: $primary;
    
        a {
            color: white;
        }
    }
    
    .ant-pagination-link-icon {
        color: white;
    }
    
    .ant-pagination-total-text {
        color: $secondary;
        padding-inline: 2rem;
    }
}

.add-cratevideos-content {

    .video-sets {
        column-gap: 1rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 2rem;
        
        .video-panel {
            border: 1px solid $border;
            border-radius: .5rem;
            height: 30rem;
        }
    }
}
